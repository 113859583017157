import type { RouteLocationRaw } from "vue-router";
import { useAuthRedirect } from "./_useAuthRedirect";

interface SignInWithOptions {
  forceRedirectTo?: RouteLocationRaw;
}

interface SignInWithPayload {
  service: "apple" | "linkedin" | "facebook" | "google";
}

interface UseSignInWith {
  (options?: SignInWithOptions): {
    signInWith: (payload: SignInWithPayload) => Promise<void>;
    isPending: Ref<boolean>;
  };
}

const servicePaths = {
  apple: "/connect/apple",
  linkedin: "/connect/linkedin",
  facebook: "/connect/facebook",
  google: "/connect/google",
};

const useSignInWithImpl = (options?: SignInWithOptions) => {
  const isPending = ref(false);
  const { getRedirectUrl } = useAuthRedirect(options);

  const createSignInWithUrl = (
    service: SignInWithPayload["service"],
    back: string,
  ) => {
    const url = new URL(apiBaseUrl());
    url.pathname = servicePaths[service];
    url.searchParams.set("back", back);

    return url;
  };

  const signInWith = async (payload: SignInWithPayload) => {
    isPending.value = true;

    const redirectUrl = getRedirectUrl("sign_in");
    const url = createSignInWithUrl(payload.service, redirectUrl);

    await navigateTo(url.href, {
      external: true,
    });

    isPending.value = false;
  };

  return {
    signInWith,
    isPending,
  };
};

export const useSignInWith: UseSignInWith = useSignInWithImpl;
