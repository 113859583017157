<template>
  <svg
    width="168"
    height="101"
    viewBox="0 0 168 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_17389_399526)">
      <path
        d="M123.865 52.1379C123.865 61.0372 118.66 67.2433 111.027 67.2433C104.897 67.2433 100.618 62.3253 100.618 55.1824C100.618 46.2831 105.822 40.077 113.456 40.077C119.585 40.077 123.865 44.9951 123.865 52.1379ZM79.9155 100.499H97.0325L99.23 78.1332C102.931 81.7632 107.904 83.6367 113.34 83.6367C126.293 83.6367 140.982 73.0981 140.982 51.5524C140.982 31.8803 129.647 23.6836 118.776 23.6836C113.456 23.6836 108.251 25.6742 104.434 29.1871L104.897 24.8546H87.7801L79.9155 100.499ZM49.2668 83.6367C66.7308 83.6367 77.3711 72.5126 79.1059 55.885L82.3443 24.8546H65.1116L61.8733 55.885C61.295 61.5056 58.288 67.2433 51.4643 67.2433C45.5658 67.2433 44.178 63.0278 44.178 58.8124C44.178 57.7585 44.2936 56.8217 44.4093 55.885L47.6476 24.8546H30.415L27.1766 55.885C27.061 57.2901 26.9453 58.6953 26.9453 59.9833C26.9453 73.5665 34.3473 83.6367 49.2668 83.6367Z"
        fill="black"
      />
      <path
        d="M139.016 0.499023V17.595H150.234V83.403H139.016V100.499H167.814V0.499023H139.016Z"
        :class="[
          props.squareColor === 'white' && 'fill-white',
          props.squareColor === 'blue' && 'fill-blue-700',
        ]"
      />
      <path
        d="M28.7982 100.499V83.403H17.5796V17.595H28.7982V0.499023H0V100.499H28.7982Z"
        :class="[
          props.squareColor === 'white' && 'fill-white',
          props.squareColor === 'blue' && 'fill-blue-700',
        ]"
      />
    </g>
    <defs>
      <clipPath id="clip0_17389_399526">
        <rect
          width="167.816"
          height="100"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    squareColor?: "blue" | "white";
  }>(),
  {
    squareColor: "blue",
  },
);
</script>
