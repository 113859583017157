import { FetchError } from "ofetch";
import { useAuthRedirect } from "./_useAuthRedirect";
import type { RouteLocationRaw } from "vue-router";

type SignInError = "wrong_credentials";

interface SignInOptions {
  forceRedirectTo?: RouteLocationRaw;
  signUpForceRedirectTo?: RouteLocationRaw;
}

interface SignInPayload {
  username: string;
  password: string;
}

interface SignInResponse {
  error: SignInError | null;
}

interface UseSignIn {
  (options?: SignInOptions): {
    signIn: (payload: SignInPayload) => Promise<SignInResponse>;
    isPending: Ref<boolean>;
  };
}

// TODO: This is a temporary implementation.
const useSignInImpl = (options?: SignInOptions) => {
  const { getRedirectUrl } = useAuthRedirect(options);
  const isPending = ref(false);

  const { t } = useI18n({
    useScope: "global",
    messages: {
      cs: { "route.login": "/prihlasit" },
      en: { "route.login": "/login" },
    },
  });

  const createLoginUrl = (back: string) => {
    const url = new URL(apiBaseUrl());

    url.pathname = t("route.login");
    url.searchParams.set("back", back);

    return url;
  };

  const checkPassword = async (payload: SignInPayload) => {
    const response = await $apiFetch.raw(`/api/login`, {
      method: "POST",
      body: payload,
      ignoreResponseError: true,
    });

    if (response.status === 200) {
      return true;
    }

    if (response.status === 401) {
      return false;
    }

    throw new FetchError(response.statusText, {
      cause: response,
    });
  };

  const signIn = async (payload: SignInPayload): Promise<SignInResponse> => {
    isPending.value = true;

    const correctPassword = await checkPassword(payload);

    // We only want to initiate a full page request if the password is correct
    if (!correctPassword) {
      isPending.value = false;
      return {
        error: "wrong_credentials",
      };
    }

    const redirectUrl = getRedirectUrl("sign_in");
    const actionUrl = createLoginUrl(redirectUrl);

    fullPageRequest(actionUrl.href, {
      method: "POST",
      body: {
        username: payload.username,
        password: payload.password,
      },
    });

    return {
      error: null,
    };
  };

  return {
    signIn,
    isPending,
  };
};

export const useSignIn: UseSignIn = useSignInImpl;
