import revive_payload_client_5FMkzu9RIh from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_k6qkf2bku6dtuqsqrj3wt5udji/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_s7qlDWpmF8 from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_k6qkf2bku6dtuqsqrj3wt5udji/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6cR0fUMQsT from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_k6qkf2bku6dtuqsqrj3wt5udji/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Xzfmb3glpe from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_k6qkf2bku6dtuqsqrj3wt5udji/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cbRQqHKQVl from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_k6qkf2bku6dtuqsqrj3wt5udji/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_2cgcyCqfrt from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_k6qkf2bku6dtuqsqrj3wt5udji/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OZ7fmGJAKj from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_k6qkf2bku6dtuqsqrj3wt5udji/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_65mNSTBycR from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.5_typescript@5.6.2_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/startupjobs.com/project/packages-js/apps/front/.nuxt/components.plugin.mjs";
import prefetch_client_g1M129cwxo from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_k6qkf2bku6dtuqsqrj3wt5udji/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_50qWObzbRt from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import sentry_client_3o99ywEQRt from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._3lck4otdit6vywzke7z5zbnqqi/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/builds/startupjobs.com/project/packages-js/apps/front/.nuxt/sentry-client-config.mjs";
import plugin_ziYcKwnJdq from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_client_3zhIt9Fx1t from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/nuxt-viewport@2.1.6_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import slideovers_lmmB9DNa7R from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@nuxt+ui@2.18.6_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.5_rollup@4.22.5_vite@5.4.8_@t_y6ffzhjlagnheham6brw2tcbfy/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_SaA24Du2s0 from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@nuxt+ui@2.18.6_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.5_rollup@4.22.5_vite@5.4.8_@t_y6ffzhjlagnheham6brw2tcbfy/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_e0EJLnL6Pl from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@nuxt+ui@2.18.6_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.5_rollup@4.22.5_vite@5.4.8_@t_y6ffzhjlagnheham6brw2tcbfy/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_qNmm3ww2KF from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.22.5_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_aW9ECTR4dm from "/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@nuxt+icon@1.5.2_magicast@0.3.5_rollup@4.22.5_vite@5.4.8_@types+node@18.19.54_terser@5.34.1___m47wdx5vktzbe2li7ddohagnlm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import api_fetch_FVcy3uQ8hr from "/builds/startupjobs.com/project/packages-js/layers/config/plugins/api-fetch.ts";
import vue_query_DWespD3ZhU from "/builds/startupjobs.com/project/packages-js/layers/config/plugins/vue-query.ts";
import route_change_client_LzBG2zCLpy from "/builds/startupjobs.com/project/packages-js/apps/front/plugins/route-change.client.ts";
export default [
  revive_payload_client_5FMkzu9RIh,
  unhead_s7qlDWpmF8,
  router_6cR0fUMQsT,
  payload_client_Xzfmb3glpe,
  navigation_repaint_client_cbRQqHKQVl,
  check_outdated_build_client_2cgcyCqfrt,
  chunk_reload_client_OZ7fmGJAKj,
  plugin_vue3_65mNSTBycR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_g1M129cwxo,
  i18n_50qWObzbRt,
  sentry_client_3o99ywEQRt,
  sentry_client_config_o34nk2sJbg,
  plugin_ziYcKwnJdq,
  plugin_client_3zhIt9Fx1t,
  slideovers_lmmB9DNa7R,
  modals_SaA24Du2s0,
  colors_e0EJLnL6Pl,
  plugin_client_qNmm3ww2KF,
  plugin_aW9ECTR4dm,
  api_fetch_FVcy3uQ8hr,
  vue_query_DWespD3ZhU,
  route_change_client_LzBG2zCLpy
]