export const useScrollStore = defineStore("scroll", () => {
  const lastMove = ref<"up" | "down" | "">("");
  const freeze = ref(false);

  function setFreeze(val: boolean) {
    freeze.value = val;
  }

  function setLastMove(newY: number, oldY: number) {
    lastMove.value = newY > oldY && newY > 0 ? "down" : "up";
  }

  return { lastMove, setLastMove, freeze, setFreeze };
});
