export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const router = useRouter();

    let isInitialLoad = true;

    router.afterEach((to) => {
      if (isInitialLoad) {
        isInitialLoad = false;
        return;
      }

      if (window.dataLayer) {
        setTimeout(() => {
          window.dataLayer.push({
            event: "nuxt_page_view",
            pageTitle: document.title,
            pageUrl: window.location.href,
          });
        }, 1);
      }
    });
  },
});
