import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { useAuthRedirect } from "./_useAuthRedirect";
import type { RouteLocationRaw } from "vue-router";

type SignUpError = "username_exists";

interface SignUpOptions {
  forceRedirectTo?: RouteLocationRaw;
  signInForceRedirectTo?: RouteLocationRaw;
}

interface SignUpPayload {
  name: string;
  username: string;
  password: string;
  personalInformationAgreement: boolean;
  type: "company" | "candidate";
}

interface SignUpResponse {
  error: SignUpError | null;
}

interface UseSignUp {
  (options?: SignUpOptions): {
    signUp: (payload: SignUpPayload) => Promise<SignUpResponse>;
    isPending: Ref<boolean>;
  };
}

// TODO: This is a temporary implementation.
// We should do a full-page request with a chained redirect, so the user is signed in on both domains.
const useSignUpImpl = (options?: SignUpOptions) => {
  const { getRedirectUrl } = useAuthRedirect(options);
  const queryClient = useQueryClient();

  const { t } = useI18n({
    useScope: "global",
    messages: {
      cs: { "route.register": "/registrovat" },
      en: { "route.register": "/register" },
    },
  });

  const checkUserExists = async (email: string) => {
    const result = await $apiFetch<{ isUsernameUnique: boolean }>(
      `/support/unique-username/${email}`,
    );
    return result.isUsernameUnique;
  };

  const { mutateAsync, isPending } = useMutation<
    SignUpResponse,
    Error,
    SignUpPayload
  >({
    mutationFn: async (payload) => {
      const isUsernameUnique = await checkUserExists(payload.username);

      if (!isUsernameUnique) {
        return {
          error: "username_exists",
        };
      }

      const { type, ...body } = payload;
      await $apiFetch(t("route.register"), {
        method: "POST",
        body,
      });

      return {
        error: null,
      };
    },
    onSuccess: async (data) => {
      if (data.error) {
        return;
      }

      const redirectUrl = getRedirectUrl("sign_up");

      await queryClient.invalidateQueries();

      return navigateTo(redirectUrl, {
        external: true, // TODO: Detect if the redirect is internal or external
      });
    },
  });

  return {
    signUp: mutateAsync,
    isPending,
  };
};

export const useSignUp: UseSignUp = useSignUpImpl;
