import { useI18n } from "vue-i18n";

export type AuthFragment =
  | "login"
  | "register"
  | "forgottenPassword"
  | "twoFA"
  | "logout";

export default function useAuthFragments() {
  const router = useRouter();

  const { t } = useI18n({
    useScope: "global",
    messages: {
      cs: {
        login: "#prihlaseni",
        register: "#registrace",
        forgottenPassword: "#zapomenute-heslo",
        twoFA: "#2fa",
        logout: "#odhlasit",
      },
      en: {
        login: "#login",
        register: "#register",
        forgottenPassword: "#forgotten-password",
        twoFA: "#2fa",
        logout: "#logout",
      },
    },
  });

  const currentFragment = computed<AuthFragment | null>(() => {
    const { hash } = router.currentRoute.value;

    switch (hash) {
      case t("login"):
        return "login";
      case t("register"):
        return "register";
      case t("forgottenPassword"):
        return "forgottenPassword";
      case t("twoFA"):
        return "twoFA";
      case t("logout"):
        return "logout";
      default:
        return null;
    }
  });

  return {
    f: t,
    currentFragment,
  };
}
