<template>
  <BaseDialog v-model:open="isOpen">
    <BaseDialogContent class="max-sm:h-full">
      <BaseDialogHeader class="grid grid-cols-[1fr_auto_1fr]">
        <BaseDialogClose as-child>
          <BaseButton size="icon" variant="secondary" type="submit">
            <IconCancel />
          </BaseButton>
        </BaseDialogClose>
        <BaseDialogTitle>
          {{ t("heading") }}
        </BaseDialogTitle>
      </BaseDialogHeader>

      <form class="p-6" @submit="onSubmit">
        <div class="-tracking-1 mb-4 text-center font-semibold">
          {{ t("label") }}
        </div>
        <InputGroup name="code" type="text" :placeholder="t('placeholder')">
          <template #iconRight>
            <IconCancel
              v-if="values.code"
              class="cursor-pointer transition-colors hover:text-blue-700"
              @click="resetForm"
            />
          </template>
        </InputGroup>

        <BaseButton
          class="mt-6 w-full"
          :class="{ 'animate-pulse': isPending }"
          :disabled="isPending"
          type="submit"
        >
          {{ t("next") }}
        </BaseButton>
      </form>
    </BaseDialogContent>
  </BaseDialog>
</template>

<script setup lang="ts">
import {
  Dialog as BaseDialog,
  DialogContent as BaseDialogContent,
  DialogHeader as BaseDialogHeader,
  DialogClose as BaseDialogClose,
  DialogTitle as BaseDialogTitle,
} from "@ui/components/base/dialog";
import { Button as BaseButton } from "@ui/components/base/button";
import IconCancel from "@ui/components/icon/Cancel.vue";
import InputGroup from "@ui/components/input/InputGroup.vue";
import { useForm, useResetForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import * as z from "zod";
import { toast } from "vue-sonner";
import { SonnerError } from "#components";
import { useAuthRouting } from "@auth/composables/_useAuthRouting";

const { closeFragment, matchFragment } = useAuthRouting();

const isOpen = computed({
  get() {
    return matchFragment("twoFA");
  },
  set(value) {
    if (!value) {
      handleClose();
    }
  },
});

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      heading: "Dvoufázové ověření",
      label: "Zadejte 6-místný kód z vaší autentificator aplikace",
      placeholder: "6-místný kód",
      next: "Pokračovat",
      logout: "Odhlásit",
      validation: {
        required: "Toto pole je povinné",
        minCharacters: "Kód musí obsahovat minimálně 6 znaků",
        incorrectCode: "Nesprávný kód, zkuste znovu",
        incorrectProcess: "Musíte zadat kód nebo se odhlásit",
      },
    },
    en: {
      heading: "Two factor authentification",
      label: "Enter the 6-digit code from your autentificator app",
      placeholder: "6-digit code",
      next: "Continue",
      validation: {
        required: "This field is mandatory",
        minCharacters: "Code must contain at least ž characters",
        incorrectCode: "Incorrect code, try again",
        incorrectProcess: "You must enter the code or log out",
        logout: "Log out",
      },
    },
  },
});

const formSchema = toTypedSchema(
  z.object({
    code: z
      .string({ required_error: t("validation.required") })
      .min(6, { message: t("validation.minCharacters") }),
  }),
);

const { handleSubmit, values } = useForm({
  validationSchema: formSchema,
});
const resetForm = useResetForm();

const { verify, isPending } = useTwoFA();

const onSubmit = handleSubmit(async (values, { setFieldError }) => {
  try {
    const result = await verify(values);
    if (result.error) {
      setFieldError("code", t("validation.incorrectCode"));
      return;
    }
  } catch (error) {
    toast.custom(markRaw(SonnerError));
  }
});

const { signOut } = useSignOut();

const handleClose = async () => {
  await signOut();
  return closeFragment();
};
</script>
