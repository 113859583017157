<template>
  <div class="flex flex-row items-center gap-x-3">
    <div
      v-for="index in props.maxScore"
      :key="index"
      :class="[
        !props.isError && props.score >= index
          ? 'bg-green-700'
          : 'bg-neutral-200',
        'h-1 w-full transition-colors',
      ]"
    />
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  maxScore: number;
  score: number;
  isError: boolean;
}>();
</script>
