<template>
  <div class="animate-in slide-in-from-bottom-[56px] duration-500">
    <div class="grid grid-cols-1 gap-3 sm:grid-cols-2">
      <ButtonExternalService
        service="google"
        :force-redirect-to="props.forceRedirectTo"
      />
      <ButtonExternalService
        service="linkedin"
        :force-redirect-to="props.forceRedirectTo"
      />
      <ButtonExternalService
        service="facebook"
        :force-redirect-to="props.forceRedirectTo"
      />
      <ButtonExternalService service="apple" />
    </div>

    <SeparatorEmail />

    <div class="animate-in slide-in-from-bottom-16 duration-500">
      <form class="space-y-4" @submit="onSubmit">
        <InputGroup
          id="username"
          name="username"
          type="email"
          :placeholder="t('placeholder.email')"
          autocomplete="username"
        >
          <template #icon>
            <IconEmail />
          </template>
        </InputGroup>

        <InputGroup
          id="current-password"
          name="password"
          :type="isPasswordDisplayed ? 'text' : 'password'"
          :placeholder="t('placeholder.password')"
          autocomplete="current-password"
        >
          <template #icon>
            <IconPasswordLock />
          </template>

          <template #iconRight>
            <button
              type="button"
              @click.prevent="isPasswordDisplayed = !isPasswordDisplayed"
            >
              <component
                :is="isPasswordDisplayed ? IconEye : IconHideEye"
                :class="
                  isPasswordDisplayed
                    ? 'text-neutral-400'
                    : 'text-black transition-colors hover:text-blue-700'
                "
              />
            </button>
          </template>

          <template #action>
            <button
              type="button"
              class="transition-colors hover:text-blue-700"
              @click="openFragment('forgottenPassword')"
            >
              {{ t("forgotPassword") }}
            </button>
          </template>
        </InputGroup>

        <div class="animate-in slide-in-from-bottom-20 duration-500">
          <BaseButton
            class="mt-8 w-full"
            type="submit"
            :disabled="isPending"
            :class="{ 'animate-pulse': isPending }"
          >
            {{ t("login") }}
          </BaseButton>
        </div>
      </form>

      <div
        class="label-base animate-in slide-in-from-bottom-[98px] mt-6 text-center duration-500"
      >
        {{ t("dontAccount") }}
        <button
          class="text-blue-700 hover:text-blue-800"
          type="button"
          @click="emit('signUp')"
        >
          {{ t("signUp") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputGroup from "@ui/components/input/InputGroup.vue";
import IconPasswordLock from "@ui/components/icon/PasswordLock.vue";
import IconEmail from "@ui/components/icon/Email.vue";
import { Button as BaseButton } from "@ui/components/base/button";
import IconHideEye from "@ui/components/icon/HideEye.vue";
import IconEye from "@ui/components/icon/Eye.vue";
import { toTypedSchema } from "@vee-validate/zod";
import * as z from "zod";
import { useForm } from "vee-validate";
import ButtonExternalService from "./_ButtonExternalService.vue";
import SeparatorEmail from "./_SeparatorEmail.vue";
import { toast } from "vue-sonner";
import { SonnerError } from "#components";
import { useAuthRouting } from "../composables/_useAuthRouting";
import { type RouteLocationRaw } from "vue-router";

const props = defineProps<{
  forceRedirectTo?: RouteLocationRaw;
}>();

const emit = defineEmits(["signUp"]);

const { openFragment } = useAuthRouting();

const isPasswordDisplayed = ref(false);

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      loginPath: "/prihlasit",
      forgotPassword: "Zapomenuté heslo?",
      login: "Přihlásit se",
      dontAccount: "Nemáte účet?",
      signUp: "Zaregistrujte se",
      placeholder: {
        email: "Váš e-mail",
        password: "Vaše heslo",
      },
      validation: {
        required: "Toto pole je povinné",
        email: "Neplatný formát e-mailu",
        minCharacters: "Heslo musí obsahovat minimálně 8 znaků",
        wrongPassword: "Heslo nebo email je nesprávný",
      },
    },
    en: {
      loginPath: "/login",
      forgotPassword: "Forgotten password?",
      login: "Log in",
      dontAccount: "Don't you have an account?",
      signUp: "Sign up",
      placeholder: {
        email: "Your e-mail address",
        password: "Your password",
      },
      validation: {
        required: "This field is mandatory",
        email: "Invalid email format",
        minCharacters: "Password must contain at least 8 characters",
        wrongPassword: "The password or email is incorrect",
      },
    },
  },
});

const formSchema = toTypedSchema(
  z.object({
    username: z
      .string({ required_error: t("validation.required") })
      .email({ message: t("validation.email") }),
    password: z
      .string({ required_error: t("validation.required") })
      .min(2, { message: t("validation.minCharacters") }),
  }),
);

const { handleSubmit } = useForm({
  validationSchema: formSchema,
});

const { signIn, isPending } = useSignIn({
  forceRedirectTo: props.forceRedirectTo,
});

const onSubmit = (event: Event) => {
  const submitHandler = handleSubmit(async (values, { setFieldError }) => {
    try {
      const result = await signIn(values);

      if (result.error && result.error === "wrong_credentials") {
        setFieldError("password", t("validation.wrongPassword"));
        return;
      }
    } catch (error) {
      toast.custom(markRaw(SonnerError));
    }
  });

  return submitHandler(event);
};
</script>
