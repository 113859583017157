<template>
  <BaseDialog v-model:open="isOpen">
    <BaseDialogContent class="max-sm:h-full">
      <BaseDialogHeader class="grid grid-cols-[1fr_auto_1fr]">
        <BaseDialogClose as-child>
          <BaseButton size="icon" variant="secondary">
            <IconCancel />
          </BaseButton>
        </BaseDialogClose>
        <BaseDialogTitle>
          {{ t("heading") }}
        </BaseDialogTitle>
      </BaseDialogHeader>

      <FormForgottenPassword />
    </BaseDialogContent>
  </BaseDialog>
</template>

<script setup lang="ts">
import {
  Dialog as BaseDialog,
  DialogContent as BaseDialogContent,
  DialogHeader as BaseDialogHeader,
  DialogClose as BaseDialogClose,
  DialogTitle as BaseDialogTitle,
} from "@ui/components/base/dialog";
import { Button as BaseButton } from "@ui/components/base/button";
import IconCancel from "@ui/components/icon/Cancel.vue";
import FormForgottenPassword from "./_FormForgottenPassword.vue";
import { useAuthRouting } from "../composables/_useAuthRouting";

const { closeFragment, matchFragment } = useAuthRouting();

const isOpen = computed({
  get() {
    return matchFragment("forgottenPassword");
  },
  set(value) {
    if (!value) {
      closeFragment();
    }
  },
});

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      heading: "Nové heslo",
    },
    en: {
      heading: "New password",
    },
  },
});
</script>
