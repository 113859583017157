import type { RouteLocationRaw } from "vue-router";
import { withBase, hasProtocol } from "ufo";

export function useAbsoluteUrl() {
  const router = useRouter();
  const requestUrl = useRequestURL();

  const getAbsoluteUrl = (to: RouteLocationRaw) => {
    // If the URL is an object, it's a route
    if (typeof to === "object") {
      const route = router.resolve(to);
      return withBase(route.href, requestUrl.origin);
    }

    // If the URL has no protocol, it's relative
    if (!hasProtocol(to)) {
      return withBase(to, requestUrl.origin);
    }

    return to;
  };

  return getAbsoluteUrl;
}
