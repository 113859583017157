
import * as cloudflareRuntime$ichrKpaDKb from '/builds/startupjobs.com/project/packages-js/node_modules/.pnpm/@nuxt+image@1.8.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.22.5_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1440,
    "2xl": 1440
  },
  "presets": {},
  "provider": "cloudflare",
  "domains": [
    "startupjobs.cz",
    "startupjobs.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$ichrKpaDKb, defaults: {"baseURL":"https://www.startupjobs.cz"} }
}
        