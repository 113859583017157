import { useAuthRedirect } from "./_useAuthRedirect";
import type { RouteLocationRaw } from "vue-router";

interface SignOutOptions {
  forceRedirectTo?: RouteLocationRaw;
}

interface UseSignOut {
  (options?: SignOutOptions): {
    signOut: () => Promise<void>;
    isPending: Ref<boolean>;
  };
}

// TODO: This is a temporary implementation.
const useSignOutImpl = (options?: SignOutOptions) => {
  const { getRedirectUrl } = useAuthRedirect(options);
  const isPending = ref(false);

  const createLogoutUrl = (back: string) => {
    const url = new URL(apiBaseUrl());

    url.pathname = "/logout";
    url.searchParams.set("back", back);

    return url;
  };

  const signOut = async () => {
    isPending.value = true;

    const redirectUrl = getRedirectUrl();
    const url = createLogoutUrl(redirectUrl);

    fullPageRequest(url.href, {
      method: "POST",
    });
  };

  return {
    signOut,
    isPending,
  };
};

export const useSignOut: UseSignOut = useSignOutImpl;
