import { default as _404J4fbTm5sUOMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/404.vue?macro=true";
import { default as _500Nzl04MRP1iMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/500.vue?macro=true";
import { default as about_45startupjobsTIOY8xjIbgMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/about-startupjobs.vue?macro=true";
import { default as adslIOcABdfnsMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/ads.vue?macro=true";
import { default as candidate_45referencesBpKdnuk1xEMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/candidate-references.vue?macro=true";
import { default as career_45pagelBF1StVybBMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/career-page.vue?macro=true";
import { default as cashbackK5TSgcx6gwMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/cashback.vue?macro=true";
import { default as contact_45usLV2KyIcPA3Meta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/contact-us.vue?macro=true";
import { default as cookiesgjIl9oOm22Meta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/cookies.vue?macro=true";
import { default as devVBJkhl5FrIMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/dev.vue?macro=true";
import { default as easter_45egg_45clientveyn1rD6opMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/easter-egg-client.vue?macro=true";
import { default as easter_45egg_45server13hN6WMyawMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/easter-egg-server.vue?macro=true";
import { default as faqs9jcaqcvt3Meta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/faq.vue?macro=true";
import { default as for_45companiessmadRCAJ4ZMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/for-companies.vue?macro=true";
import { default as for_45mediapEgVbE8PJKMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/for-media.vue?macro=true";
import { default as gdpr9ANsuUbMqrMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/gdpr.vue?macro=true";
import { default as goldSUm0hSdyB9Meta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/gold.vue?macro=true";
import { default as homepage_45managerjUg3EGawmLMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/homepage-manager.vue?macro=true";
import { default as iconsdX67GbJDi1Meta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/icons.vue?macro=true";
import { default as index67dVyyQDtcMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/index.vue?macro=true";
import { default as _91hash_93uBXEgLyaxlMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/job/[id]/[slug]/[hash].vue?macro=true";
import { default as _91_46_46_46slugs_93MR3oVGjSlSMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/jobs/[...slugs].vue?macro=true";
import { default as savedG6P6Miy0amMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/jobs/saved.vue?macro=true";
import { default as jobsj3vbRgSdrKMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/jobs.vue?macro=true";
import { default as knowledge_45testsDC9THgec3zMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/knowledge-tests.vue?macro=true";
import { default as level_45upaqqC9h2QE1Meta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/level-up.vue?macro=true";
import { default as move_45forwardNPRWxfIoNVMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/move-forward.vue?macro=true";
import { default as narozeniny_452024EZiWnL4cY4Meta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/narozeniny-2024.vue?macro=true";
import { default as new_45visual_45identityXKYDwcdwhgMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/new-visual-identity.vue?macro=true";
import { default as _91_91fields_93_93VULVifj64CMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/features/FeatureOfferList/pages/new/jobs/[[fields]].vue?macro=true";
import { default as saved63Mk1HeADVMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/features/FeatureOfferList/pages/new/jobs/saved.vue?macro=true";
import { default as jobsdKF0WVFeFEMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/features/FeatureOfferList/pages/new/jobs.vue?macro=true";
import { default as premium_45articlegDUsSC99EhMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/premium-article.vue?macro=true";
import { default as pricelist45viCc1Q1KMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/pricelist.vue?macro=true";
import { default as rulesieUgifeE8TMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/rules.vue?macro=true";
import { default as companiesOz0JbNGwplMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startup-program/companies.vue?macro=true";
import { default as startupsegjPKryH5UMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startup-program/startups.vue?macro=true";
import { default as _91slug_93ROE2Rqyb0yMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startup/[slug].vue?macro=true";
import { default as _91chapterSlug_93L9rFUn5BLXMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/futures/[futureSlug]/chapter/[chapterSlug].vue?macro=true";
import { default as index13VdVk6HcXMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/futures/[futureSlug]/index.vue?macro=true";
import { default as index9g37knepyEMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/futures/index.vue?macro=true";
import { default as indexfthJOnkOBfMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/index.vue?macro=true";
import { default as startupedu297RWPQh7F4Meta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu2.vue?macro=true";
import { default as indexYxRAbPO6jkMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startupmarket/[id]/index.vue?macro=true";
import { default as created9HsmW1KExGMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startupmarket/created.vue?macro=true";
import { default as indexUDZCjnVyTHMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startupmarket/index.vue?macro=true";
import { default as startupsftwzTdo4wFMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startups.vue?macro=true";
import { default as startuptalkLSyTzGc1qPMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/startuptalk.vue?macro=true";
import { default as successful_45candidates363Y5hLB4OMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/successful-candidates.vue?macro=true";
import { default as tariffs5v51HBgj4hMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/tariffs.vue?macro=true";
import { default as terms_45and_45conditionsmYqihEKevYMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/terms-and-conditions.vue?macro=true";
import { default as _91id_93SJHbmfNRHqMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/[slug]/result/[id].vue?macro=true";
import { default as _91slug_93HAziJeKOvMMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/[slug].vue?macro=true";
import { default as dataczhisvxcECMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/data.ts?macro=true";
import { default as index7rjGVShFzzMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/index.vue?macro=true";
import { default as _91guid_9371SL4CW9GgMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/login/[guid].vue?macro=true";
import { default as tips_45for_45advertisingm2wmPxzRDtMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/tips-for-advertising.vue?macro=true";
import { default as two_45factorWFuJoHK2wBMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/two-factor.vue?macro=true";
import { default as velikonoce_452024mC3sJSVRzZMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/velikonoce-2024.vue?macro=true";
import { default as what_45is_45a_45startup6W4bH1yfKZMeta } from "/builds/startupjobs.com/project/packages-js/apps/front/pages/what-is-a-startup.vue?macro=true";
export default [
  {
    name: "404___cs",
    path: "/404",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/404.vue")
  },
  {
    name: "404___en",
    path: "/404",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/404.vue")
  },
  {
    name: "500___cs",
    path: "/500",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/500.vue")
  },
  {
    name: "500___en",
    path: "/500",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/500.vue")
  },
  {
    name: "about-startupjobs___cs",
    path: "/o-startupjobs",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/about-startupjobs.vue")
  },
  {
    name: "about-startupjobs___en",
    path: "/about-startupjobs",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/about-startupjobs.vue")
  },
  {
    name: "ads___cs",
    path: "/pro-firmy/reklama",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/ads.vue")
  },
  {
    name: "ads___en",
    path: "/for-companies/ads",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/ads.vue")
  },
  {
    name: "candidate-references___cs",
    path: "/reference-kandidatu",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/candidate-references.vue")
  },
  {
    name: "candidate-references___en",
    path: "/candidate-references",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/candidate-references.vue")
  },
  {
    name: "career-page___cs",
    path: "/pro-firmy/karierni-stranky",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/career-page.vue")
  },
  {
    name: "career-page___en",
    path: "/for-companies/career-page",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/career-page.vue")
  },
  {
    name: "cashback___cs",
    path: "/pro-firmy/cashback",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/cashback.vue")
  },
  {
    name: "cashback___en",
    path: "/for-companies/cashback",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/cashback.vue")
  },
  {
    name: "contact-us___cs",
    path: "/kontakt",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/contact-us.vue")
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/contact-us.vue")
  },
  {
    name: "cookies___cs",
    path: "/cookies",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/cookies.vue")
  },
  {
    name: "cookies___en",
    path: "/cookies",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/cookies.vue")
  },
  {
    name: "dev___cs",
    path: "/pro-firmy/dev",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/dev.vue")
  },
  {
    name: "dev___en",
    path: "/for-companies/dev",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/dev.vue")
  },
  {
    name: "easter-egg-client___cs",
    path: "/easter-egg-client",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/easter-egg-client.vue")
  },
  {
    name: "easter-egg-client___en",
    path: "/easter-egg-client",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/easter-egg-client.vue")
  },
  {
    name: "easter-egg-server___cs",
    path: "/easter-egg-server",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/easter-egg-server.vue")
  },
  {
    name: "easter-egg-server___en",
    path: "/easter-egg-server",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/easter-egg-server.vue")
  },
  {
    name: "faq___cs",
    path: "/pro-firmy/napoveda",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/for-companies/faq",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/faq.vue")
  },
  {
    name: "for-companies___cs",
    path: "/pro-firmy",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/for-companies.vue")
  },
  {
    name: "for-companies___en",
    path: "/for-companies",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/for-companies.vue")
  },
  {
    name: "for-media___cs",
    path: "/pro-media",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/for-media.vue")
  },
  {
    name: "for-media___en",
    path: "/for-media",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/for-media.vue")
  },
  {
    name: "gdpr___cs",
    path: "/osobni-udaje",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/gdpr.vue")
  },
  {
    name: "gdpr___en",
    path: "/personal-information",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/gdpr.vue")
  },
  {
    name: "gold___cs",
    path: "/pro-firmy/gold",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/gold.vue")
  },
  {
    name: "gold___en",
    path: "/for-companies/gold",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/gold.vue")
  },
  {
    name: "homepage-manager___cs",
    path: "/start",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/homepage-manager.vue")
  },
  {
    name: "homepage-manager___en",
    path: "/start",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/homepage-manager.vue")
  },
  {
    name: "icons___cs",
    path: "/icons",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/icons.vue")
  },
  {
    name: "icons___en",
    path: "/icons",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/icons.vue")
  },
  {
    name: "index___cs",
    path: "/",
    meta: index67dVyyQDtcMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: index67dVyyQDtcMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/index.vue")
  },
  {
    name: "job-id-slug-hash___cs",
    path: "/nabidka/:id()/:slug()/:hash?",
    meta: _91hash_93uBXEgLyaxlMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/job/[id]/[slug]/[hash].vue")
  },
  {
    name: "job-id-slug-hash___en",
    path: "/job/:id()/:slug()/:hash?",
    meta: _91hash_93uBXEgLyaxlMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/job/[id]/[slug]/[hash].vue")
  },
  {
    name: "jobs___cs",
    path: "/nabidky",
    meta: jobsj3vbRgSdrKMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/jobs.vue"),
    children: [
  {
    name: "jobs-slugs___cs",
    path: ":slugs(.*)*",
    meta: _91_46_46_46slugs_93MR3oVGjSlSMeta || {},
    alias: ["/nabidky","/jobs"],
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/jobs/[...slugs].vue")
  },
  {
    name: "jobs-saved___cs",
    path: "ulozene",
    meta: savedG6P6Miy0amMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/jobs/saved.vue")
  }
]
  },
  {
    name: "jobs___en",
    path: "/jobs",
    meta: jobsj3vbRgSdrKMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/jobs.vue"),
    children: [
  {
    name: "jobs-slugs___en",
    path: ":slugs(.*)*",
    meta: _91_46_46_46slugs_93MR3oVGjSlSMeta || {},
    alias: ["/nabidky","/jobs"],
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/jobs/[...slugs].vue")
  },
  {
    name: "jobs-saved___en",
    path: "saved",
    meta: savedG6P6Miy0amMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/jobs/saved.vue")
  }
]
  },
  {
    name: "knowledge-tests___cs",
    path: "/otestujte-sve-znalosti",
    meta: knowledge_45testsDC9THgec3zMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/knowledge-tests.vue")
  },
  {
    name: "knowledge-tests___en",
    path: "/show-off-your-knowledge",
    meta: knowledge_45testsDC9THgec3zMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/knowledge-tests.vue")
  },
  {
    name: "level-up___cs",
    path: "/posun-vpred",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/level-up.vue")
  },
  {
    name: "level-up___en",
    path: "/level-up",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/level-up.vue")
  },
  {
    name: "move-forward___cs",
    path: "/posunte-se-vpred",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/move-forward.vue")
  },
  {
    name: "move-forward___en",
    path: "/move-forward",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/move-forward.vue")
  },
  {
    name: "narozeniny-2024___cs",
    path: "/pro-firmy/narozeniny-2024",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/narozeniny-2024.vue")
  },
  {
    name: "narozeniny-2024___en",
    path: "/for-companies/narozeniny-2024",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/narozeniny-2024.vue")
  },
  {
    name: "new-visual-identity___cs",
    path: "/nova-vizualni-identita",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/new-visual-identity.vue")
  },
  {
    name: "new-visual-identity___en",
    path: "/new-visual-identity",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/new-visual-identity.vue")
  },
  {
    name: "new-jobs___cs",
    path: "/new/nabidky",
    meta: jobsdKF0WVFeFEMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/features/FeatureOfferList/pages/new/jobs.vue"),
    children: [
  {
    name: "new-jobs-fields___cs",
    path: ":fields?",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/features/FeatureOfferList/pages/new/jobs/[[fields]].vue")
  },
  {
    name: "new-jobs-saved___cs",
    path: "ulozene",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/features/FeatureOfferList/pages/new/jobs/saved.vue")
  }
]
  },
  {
    name: "new-jobs___en",
    path: "/new/jobs",
    meta: jobsdKF0WVFeFEMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/features/FeatureOfferList/pages/new/jobs.vue"),
    children: [
  {
    name: "new-jobs-fields___en",
    path: ":fields?",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/features/FeatureOfferList/pages/new/jobs/[[fields]].vue")
  },
  {
    name: "new-jobs-saved___en",
    path: "saved",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/features/FeatureOfferList/pages/new/jobs/saved.vue")
  }
]
  },
  {
    name: "premium-article___cs",
    path: "/pro-firmy/premium-clanek",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/premium-article.vue")
  },
  {
    name: "premium-article___en",
    path: "/for-companies/premium-article",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/premium-article.vue")
  },
  {
    name: "pricelist___cs",
    path: "/pro-firmy/cenik",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/pricelist.vue")
  },
  {
    name: "pricelist___en",
    path: "/for-companies/pricelist",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/pricelist.vue")
  },
  {
    name: "rules___cs",
    path: "/pro-firmy/pravidla-inzerce",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/rules.vue")
  },
  {
    name: "rules___en",
    path: "/for-companies/startupjobs-rules",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/rules.vue")
  },
  {
    name: "startup-program-companies___cs",
    path: "/pro-firmy/stredni-a-velke-firmy",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startup-program/companies.vue")
  },
  {
    name: "startup-program-companies___en",
    path: "/for-companies/medium-and-large-companies",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startup-program/companies.vue")
  },
  {
    name: "startup-program-startups___cs",
    path: "/pro-firmy/startup-program",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startup-program/startups.vue")
  },
  {
    name: "startup-program-startups___en",
    path: "/for-companies/startup-program",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startup-program/startups.vue")
  },
  {
    name: "startup-slug___cs",
    path: "/startup/:slug()",
    meta: _91slug_93ROE2Rqyb0yMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startup/[slug].vue")
  },
  {
    name: "startup-slug___en",
    path: "/startup/:slug()",
    meta: _91slug_93ROE2Rqyb0yMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startup/[slug].vue")
  },
  {
    name: "startupedu-futures-futureSlug-chapter-chapterSlug___cs",
    path: "/startupedu/futures/:futureSlug()/kapitola/:chapterSlug()",
    meta: _91chapterSlug_93L9rFUn5BLXMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/futures/[futureSlug]/chapter/[chapterSlug].vue")
  },
  {
    name: "startupedu-futures-futureSlug-chapter-chapterSlug___en",
    path: "/startupedu/futures/:futureSlug()/chapter/:chapterSlug()",
    meta: _91chapterSlug_93L9rFUn5BLXMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/futures/[futureSlug]/chapter/[chapterSlug].vue")
  },
  {
    name: "startupedu-futures-futureSlug___cs",
    path: "/startupedu/futures/:futureSlug()",
    meta: index13VdVk6HcXMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/futures/[futureSlug]/index.vue")
  },
  {
    name: "startupedu-futures-futureSlug___en",
    path: "/startupedu/futures/:futureSlug()",
    meta: index13VdVk6HcXMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/futures/[futureSlug]/index.vue")
  },
  {
    name: "startupedu-futures___cs",
    path: "/startupedu/futures",
    meta: index9g37knepyEMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/futures/index.vue")
  },
  {
    name: "startupedu-futures___en",
    path: "/startupedu/futures",
    meta: index9g37knepyEMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/futures/index.vue")
  },
  {
    name: "startupedu___cs",
    path: "/startupedu",
    meta: indexfthJOnkOBfMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/index.vue")
  },
  {
    name: "startupedu___en",
    path: "/startupedu",
    meta: indexfthJOnkOBfMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu/index.vue")
  },
  {
    name: "startupedu2___cs",
    path: "/startupedu2",
    meta: startupedu297RWPQh7F4Meta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu2.vue")
  },
  {
    name: "startupedu2___en",
    path: "/startupedu2",
    meta: startupedu297RWPQh7F4Meta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupedu2.vue")
  },
  {
    name: "startupmarket-id___cs",
    path: "/startupmarket/:id()",
    meta: indexYxRAbPO6jkMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupmarket/[id]/index.vue")
  },
  {
    name: "startupmarket-id___en",
    path: "/startupmarket/:id()",
    meta: indexYxRAbPO6jkMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupmarket/[id]/index.vue")
  },
  {
    name: "startupmarket-created___cs",
    path: "/startupmarket/vytvorene",
    meta: created9HsmW1KExGMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupmarket/created.vue")
  },
  {
    name: "startupmarket-created___en",
    path: "/startupmarket/created",
    meta: created9HsmW1KExGMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupmarket/created.vue")
  },
  {
    name: "startupmarket___cs",
    path: "/startupmarket",
    meta: indexUDZCjnVyTHMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupmarket/index.vue")
  },
  {
    name: "startupmarket___en",
    path: "/startupmarket",
    meta: indexUDZCjnVyTHMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startupmarket/index.vue")
  },
  {
    name: "startups___cs",
    path: "/firmy",
    meta: startupsftwzTdo4wFMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startups.vue")
  },
  {
    name: "startups___en",
    path: "/companies",
    meta: startupsftwzTdo4wFMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startups.vue")
  },
  {
    name: "startuptalk___cs",
    path: "/startuptalk",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startuptalk.vue")
  },
  {
    name: "startuptalk___en",
    path: "/startuptalk",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/startuptalk.vue")
  },
  {
    name: "successful-candidates___cs",
    path: "/co-udelali-uspesni-kandidati-na-startupjobs-jinak",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/successful-candidates.vue")
  },
  {
    name: "successful-candidates___en",
    path: "/successful-candidates",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/successful-candidates.vue")
  },
  {
    name: "tariffs___cs",
    path: "/pro-firmy/tarify",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tariffs.vue")
  },
  {
    name: "tariffs___en",
    path: "/for-companies/tariffs",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tariffs.vue")
  },
  {
    name: "terms-and-conditions___cs",
    path: "/vop",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-of-service",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/terms-and-conditions.vue")
  },
  {
    name: "tests-slug___cs",
    path: "/test/:slug()",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/[slug].vue"),
    children: [
  {
    name: "tests-slug-result-id___cs",
    path: "result/:id()",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/[slug]/result/[id].vue")
  }
]
  },
  {
    name: "tests-slug___en",
    path: "/test/:slug()",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/[slug].vue"),
    children: [
  {
    name: "tests-slug-result-id___en",
    path: "result/:id()",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/[slug]/result/[id].vue")
  }
]
  },
  {
    name: "tests-data___cs",
    path: "/tests/data",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/data.ts")
  },
  {
    name: "tests-data___en",
    path: "/tests/data",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/data.ts")
  },
  {
    name: "tests___cs",
    path: "/testy",
    meta: index7rjGVShFzzMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/index.vue")
  },
  {
    name: "tests___en",
    path: "/tests",
    meta: index7rjGVShFzzMeta || {},
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/index.vue")
  },
  {
    name: "tests-login-guid___cs",
    path: "/test/:slug()/finished/:guid()/login",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/login/[guid].vue")
  },
  {
    name: "tests-login-guid___en",
    path: "/test/:slug()/finished/:guid()/login",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tests/login/[guid].vue")
  },
  {
    name: "tips-for-advertising___cs",
    path: "/pro-firmy/tipy-pro-inzerci",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tips-for-advertising.vue")
  },
  {
    name: "tips-for-advertising___en",
    path: "/for-companies/tips-for-advertising",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/tips-for-advertising.vue")
  },
  {
    name: "two-factor___cs",
    path: "/two-factor",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/two-factor.vue")
  },
  {
    name: "two-factor___en",
    path: "/two-factor",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/two-factor.vue")
  },
  {
    name: "velikonoce-2024___cs",
    path: "/pro-firmy/velikonoce-2024",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/velikonoce-2024.vue")
  },
  {
    name: "velikonoce-2024___en",
    path: "/for-companies/velikonoce-2024",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/velikonoce-2024.vue")
  },
  {
    name: "what-is-a-startup___cs",
    path: "/co-je-startup",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/what-is-a-startup.vue")
  },
  {
    name: "what-is-a-startup___en",
    path: "/what-is-a-startup",
    component: () => import("/builds/startupjobs.com/project/packages-js/apps/front/pages/what-is-a-startup.vue")
  }
]