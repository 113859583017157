export const fullPageRequest = (
  url: string,
  options: {
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
    body?: Record<string, string>;
  },
) => {
  const form = document.createElement("form");
  form.method = options.method || "GET";
  form.action = url;
  form.style.display = "none";

  if (options.body) {
    form.enctype = "multipart/form-data";
    for (const [key, value] of Object.entries(options.body)) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = value;
      form.appendChild(input);
    }
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
