<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_16334_120765)">
      <path
        d="M12 18.5C5.9 18.5 3 12 3 12C3 12 5.89 5.5 12 5.5"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M12 5.5C18.11 5.5 21 12 21 12C21 12 18.11 18.5 12 18.5"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_16334_120765">
        <rect
          width="20.19"
          height="15"
          fill="white"
          transform="translate(1.90625 4.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
