import { FetchError } from "ofetch";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { useAuthRedirect } from "./_useAuthRedirect";
import type { RouteLocationRaw } from "vue-router";

type UseTwoFAVerifyError = "incorrect_code";

interface UseTwoFAVerifyOptions {
  forceRedirectTo?: RouteLocationRaw;
}

interface UseTwoFAVerifyPayload {
  code: string;
}

interface UseTwoFAVerifyResponse {
  error: UseTwoFAVerifyError | null;
}

interface UseTwoFA {
  (options?: UseTwoFAVerifyOptions): {
    verify: (payload: UseTwoFAVerifyPayload) => Promise<UseTwoFAVerifyResponse>;
    isPending: Ref<boolean>;
  };
}

// TODO: This is a temporary implementation.
// We should do a full-page request with a chained redirect, so the user is verified on both domains.
function useTwoFAImpl(options?: UseTwoFAVerifyOptions) {
  const { getRedirectUrl } = useAuthRedirect(options);
  const queryClient = useQueryClient();

  const checkCode = async (payload: UseTwoFAVerifyPayload) => {
    const result = await $apiFetch.raw("/2fa_check", {
      method: "POST",
      body: payload,
    });

    if (result.status === 200) {
      return true;
    }

    if (result.status === 401) {
      return false;
    }

    throw new FetchError(result.statusText, {
      cause: result,
    });
  };

  const { mutateAsync, isPending } = useMutation<
    UseTwoFAVerifyResponse,
    Error,
    UseTwoFAVerifyPayload
  >({
    mutationFn: async (payload) => {
      const isCodeValid = await checkCode(payload);

      if (!isCodeValid) {
        return {
          error: "incorrect_code",
        };
      }

      return {
        error: null,
      };
    },
    onSuccess: async () => {
      const redirectUrl = getRedirectUrl();

      await queryClient.invalidateQueries();

      return navigateTo(redirectUrl, {
        external: true, // TODO: Detect if the redirect is internal or external
      });
    },
  });

  return {
    verify: mutateAsync,
    isPending,
  };
}

export const useTwoFA: UseTwoFA = useTwoFAImpl;
