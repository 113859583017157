import { useMutation } from "@tanstack/vue-query";

interface RequestPasswordResetResponse {
  error: null;
}

interface RequestPasswordResetPayload {
  email: string;
}

interface UseRequestPasswordReset {
  (): {
    requestPasswordReset: (
      payload: RequestPasswordResetPayload,
    ) => Promise<RequestPasswordResetResponse>;
    isPending: Ref<boolean>;
  };
}

const useRequestPasswordResetImpl = () => {
  const { t } = useI18n({
    useScope: "global",
    messages: {
      cs: { "route.forgottenPassword": "/obnovit-heslo" },
      en: { "route.forgottenPassword": "/reset-password" },
    },
  });

  const { mutateAsync, isPending } = useMutation<
    RequestPasswordResetResponse,
    Error,
    RequestPasswordResetPayload
  >({
    mutationFn: async (payload) => {
      const body = new FormData();
      body.append("email", payload.email);

      await $apiFetch(t("route.forgottenPassword"), {
        method: "POST",
        body,
      });

      return {
        error: null,
      };
    },
  });

  return {
    requestPasswordReset: mutateAsync,
    isPending,
  };
};

export const useRequestPasswordReset: UseRequestPasswordReset =
  useRequestPasswordResetImpl;
