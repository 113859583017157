<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
  >
    <g clip-path="url(#clip0_39670_3903)">
      <path
        d="M19 1H1V15H19V1Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M18.5 1L10 9L1.5 1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1 15.0025L6.69 9.3125"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M19.0025 15.0025L13.3125 9.3125"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_39670_3903">
        <rect
          width="19.5"
          height="15.5"
          fill="white"
          transform="translate(0.25 0.25)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
