import { useQuery } from "@tanstack/vue-query";

export const useIntercomQuery = () => {
  return useQuery({
    queryKey: ['intercom'],
    queryFn: async () =>
      await $apiFetch<any>(
        `/api/intercom`,
      ),
  });
};
