import { type VariantProps, cva } from "class-variance-authority";

export { default as Button } from "./Button.vue";

export const buttonVariants = cva(
  "-tracking-1 inline-flex items-center justify-center whitespace-nowrap font-sans font-semibold leading-[110%] ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed",
  {
    variants: {
      variant: {
        primary:
          "bg-blue-700 text-white hover:bg-blue-800 active:bg-blue-700 disabled:bg-blue-400 disabled:hover:bg-blue-200",
        secondary:
          "border-1.5 border-neutral-200 bg-white text-neutral-950 hover:border-blue-700 active:text-blue-700 disabled:hover:border-neutral-200",
        black:
          "bg-black text-white hover:bg-neutral-900 active:bg-black active:text-white disabled:hover:bg-black",
        destructive:
          "bg-red-500 text-white hover:bg-red-600 active:bg-red-500 disabled:hover:bg-red-500",
        light:
          "text-black disabled:hover:text-black sm:hover:text-blue-700 sm:active:text-black",
        link: "font-semibold text-blue-700 hover:text-blue-800 active:text-black disabled:hover:text-blue-700",
        outline:
          "border-1.5 border-black bg-transparent text-black hover:bg-black hover:text-white active:bg-transparent active:text-black disabled:hover:bg-transparent disabled:hover:text-black",
      },
      size: {
        sm: "h-10 px-4 py-1.5",
        md: "h-11 px-4 py-2",
        lg: "h-12 px-5 py-3",
        xl: "h-15 px-5 py-3",
        icon: "size-11 p-2.5",
        flexible: "",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "lg",
    },
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
