const _inherit = "inherit"
const _current = "currentColor"
const _transparent = "transparent"
const _black = "#000"
const _white = "#fff"
const _blue = {"200":"#BFCEFF","400":"#809EFF","600":"#406EFF","700":"#003DFF","800":"#0031CC"}
const _green = {"100":"#BFFCE1","200":"#80F9C3","300":"#40F5A4","400":"#00F286","700":"#00AF5B"}
const _gold = {"100":"#EFE8D9","200":"#DFD1B3","300":"#CFBB8D","400":"#BFA467"}
const _red = {"200":"#FFC8CE","300":"#FF919D","400":"#FF5B6C","500":"#FF243B","600":"#D20E30"}
const _yellow = {"500":"#FFDF00"}
const _pink = {"300":"#FFAFFF"}
const _purple = {"500":"#963CFF"}
const _zinc = {"500":"#828384"}
const _neutral = {"50":"#FCFCFC","100":"#F3F3F6","200":"#EEEEF2","300":"#E5E5E9","400":"#9A9FA5","500":"#6F767E","800":"#33383F","900":"#272B30","950":"#1A1D1F"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const config = { "inherit": _inherit, "current": _current, "transparent": _transparent, "black": _black, "white": _white, "blue": _blue, "green": _green, "gold": _gold, "red": _red, "yellow": _yellow, "pink": _pink, "purple": _purple, "zinc": _zinc, "neutral": _neutral, "primary": _primary, "gray": _gray,  }
export { config as default, _inherit, _current, _transparent, _black, _white, _blue, _green, _gold, _red, _yellow, _pink, _purple, _zinc, _neutral, _primary, _gray }