import { useAuthRedirect, type AuthRedirectOptions } from "./_useAuthRedirect";
import type { AuthFragment } from "./useAuthFragments";
import { withoutHost } from "ufo";

export const useAuthRouting = (options?: AuthRedirectOptions) => {
  const { toQueryParams, getEmptyQueryParams, fromQueryParams } =
    useAuthRedirect(options);
  const { currentFragment, f } = useAuthFragments();
  const router = useRouter();

  const openFragment = async (fragment: AuthFragment) => {
    const query = toQueryParams();

    return navigateTo({
      ...router.currentRoute.value,
      query: {
        ...router.currentRoute.value.query,
        ...query,
      },
      hash: f(fragment),
    });
  };

  const closeFragment = () => {
    const redirectTo = fromQueryParams("redirect_to");
    if (redirectTo) {
      return navigateTo(withoutHost(redirectTo));
    }

    return navigateTo({
      ...router.currentRoute.value,
      query: {
        ...router.currentRoute.value.query,
        ...getEmptyQueryParams(),
      },
      hash: "",
    });
  };

  const matchFragment = (fragment: AuthFragment) => {
    return currentFragment.value === fragment;
  };

  return {
    openFragment,
    closeFragment,
    matchFragment,
  };
};
