<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
  RadioGroupIndicator,
  RadioGroupItem,
  type RadioGroupItemProps,
  useForwardProps,
} from "radix-vue";
import { cn } from "@ui/lib/utils";

const props = defineProps<
  RadioGroupItemProps & { class?: HTMLAttributes["class"] }
>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <RadioGroupItem
    v-bind="forwardedProps"
    :class="
      cn(
        'border-1.5 flex aspect-square size-6 items-center justify-center rounded-full border-neutral-300 text-neutral-900 ring-offset-white transition-colors hover:border-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-blue-700 data-[state=checked]:bg-blue-700 hover:data-[state=checked]:bg-blue-800',
        props.class,
      )
    "
  >
    <RadioGroupIndicator class="size-3 rounded-full bg-neutral-50 shadow" />
  </RadioGroupItem>
</template>
