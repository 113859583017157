<template>
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_19914_316216)">
      <path
        d="M17 9H1V19H17V9Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M9 14C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M9 16V13"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M4 8V6C4 3.24 6.24 1 9 1C11.76 1 14 3.24 14 6V7"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M4 7V9"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_19914_316216">
        <rect width="18" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
