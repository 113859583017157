import { useAbsoluteUrl } from "./_useAbsoluteUrl";
import { useAuthContextOrThrow } from "./_useAuthContext";
import type { RouteLocationRaw } from "vue-router";
import { withoutFragment } from "ufo";

export interface AuthRedirectOptions {
  forceRedirectTo?: RouteLocationRaw;
  signUpForceRedirectTo?: RouteLocationRaw;
  signInForceRedirectTo?: RouteLocationRaw;
}

interface AuthQueryParams {
  redirect_to: string;
  force_redirect_to?: string;
  sign_up_force_redirect_to?: string;
  sign_in_force_redirect_to?: string;
}

export function useAuthRedirect(options: AuthRedirectOptions = {}) {
  const absoluteUrl = useAbsoluteUrl();
  const router = useRouter();
  const authContext = useAuthContextOrThrow();

  const toQueryParams = () => {
    const { forceRedirectTo, signUpForceRedirectTo, signInForceRedirectTo } =
      options;

    const query: AuthQueryParams = {
      redirect_to: absoluteUrl(router.currentRoute.value),
    };

    if (forceRedirectTo) {
      query.force_redirect_to = absoluteUrl(forceRedirectTo);
    }

    if (signUpForceRedirectTo) {
      query.sign_up_force_redirect_to = absoluteUrl(signUpForceRedirectTo);
    }

    if (signInForceRedirectTo) {
      query.sign_in_force_redirect_to = absoluteUrl(signInForceRedirectTo);
    }

    return query;
  };

  const fromQueryParams = (key: string) => {
    const value = router.currentRoute.value.query[key];
    if (typeof value === "string") {
      return value;
    }

    if (Array.isArray(value)) {
      return value[0];
    }
  };

  const getRedirectUrl = (flow?: "sign_up" | "sign_in") => {
    let redirectTo: RouteLocationRaw | undefined;

    if (flow === "sign_up") {
      // Prioritize specific force redirect options
      redirectTo =
        fromQueryParams("sign_up_force_redirect_to") ||
        options?.signUpForceRedirectTo ||
        authContext.signUpForceRedirectTo;
    }

    if (flow === "sign_in") {
      // Prioritize specific force redirect options
      redirectTo =
        fromQueryParams("sign_in_force_redirect_to") ||
        options?.signInForceRedirectTo ||
        authContext.signInForceRedirectTo;
    }

    // Fallback to the general force redirect option
    redirectTo ||=
      fromQueryParams("force_redirect_to") || options?.forceRedirectTo;

    // Fallback to the redirect_to query parameter or current route
    redirectTo ||=
      fromQueryParams("redirect_to") ||
      withoutFragment(router.currentRoute.value.fullPath);

    return absoluteUrl(redirectTo);
  };

  const getEmptyQueryParams = () => {
    return {
      redirect_to: undefined,
      force_redirect_to: undefined,
      sign_up_force_redirect_to: undefined,
      sign_in_force_redirect_to: undefined,
    };
  };

  return {
    toQueryParams,
    getRedirectUrl,
    getEmptyQueryParams,
    fromQueryParams,
  };
}
