<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
  >
    <g clip-path="url(#clip0_16334_78871)">
      <path
        d="M17.9922 2.22461L6.45219 13.7746L1.99219 9.31461"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_16334_78871">
        <rect
          width="18.83"
          height="14.37"
          fill="white"
          transform="translate(0.585938 0.814453)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
