<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { cn } from "@ui/lib/utils";

const props = defineProps<{
  class?: HTMLAttributes["class"];
}>();
</script>

<template>
  <div
    :class="
      cn(
        'shadow-header z-10 flex flex-row items-center justify-between px-6 py-3',
        props.class,
      )
    "
  >
    <slot />
  </div>
</template>
