<template>
  <BaseSonnerStructure variant="success">
    <template #message>
      {{ t("title") }}
    </template>

    <template #description>
      {{ t("message") }}
    </template>
  </BaseSonnerStructure>
</template>

<script setup lang="ts">
import { SonnerStructure as BaseSonnerStructure } from "@ui/components/base/sonner";

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      title: "Odkaz pro obnovu hesla odeslán",
      message:
        "Nedorazil? Zkontrolujte prosím i spam/složku nevyžádané pošty, než odkaz odešlete znovu",
    },
    en: {
      title: "Password reset link sent",
      message:
        "Did not arrive? Please check also spam/junk folder, before sending the link again",
    },
  },
});
</script>
