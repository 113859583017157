<template>
  <BaseTabs v-model="currentTab">
    <BaseTabsList>
      <BaseTabsTrigger value="login">
        {{ t("login") }}
      </BaseTabsTrigger>

      <BaseTabsTrigger value="registration">
        {{ t("registration") }}
      </BaseTabsTrigger>

      <BaseTabsIndicator />
    </BaseTabsList>

    <div class="mt-6">
      <BaseTabsContent value="login">
        <FormSignIn
          :force-redirect-to="props.signUpForceRedirectTo"
          @sign-up="currentTab = 'registration'"
        />
      </BaseTabsContent>
      <BaseTabsContent value="registration">
        <FormSignUp
          :force-redirect-to="props.signInForceRedirectTo"
          @login="currentTab = 'login'"
        />
      </BaseTabsContent>
    </div>
  </BaseTabs>
</template>

<script setup lang="ts">
import {
  Tabs as BaseTabs,
  TabsList as BaseTabsList,
  TabsTrigger as BaseTabsTrigger,
  TabsContent as BaseTabsContent,
  TabsIndicator as BaseTabsIndicator,
} from "@ui/components/base/tabs";
import FormSignIn from "./_FormSignIn.vue";
import FormSignUp from "./_FormSignUp.vue";
import { type RouteLocationRaw } from "vue-router";

const props = defineProps<{
  signInForceRedirectTo?: RouteLocationRaw;
  signUpForceRedirectTo?: RouteLocationRaw;
}>();

const currentTab = defineModel<"login" | "registration">();

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      login: "Přihlášení",
      registration: "Registrace",
    },
    en: {
      login: "Login",
      registration: "Registration",
    },
  },
});
</script>
