import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    // If navigating back or forward using the browser buttons, use savedPosition
    if (savedPosition) {
      return savedPosition;
    }

    // If a hash is present, scroll to it
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 80, // Offset to account for fixed header
      };
    }

    // If navigating to the same page but without a hash (eg. closing a fragment), do nothing
    // NOTE: This breaks navigating from an anchor the same page without the anchor (eg. /#section -> /)
    if (to.name === from.name && from.hash) return;

    // Otherwise, scroll to top
    return {
      top: 0,
    };
  },
};
