
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "en"
]

export const localeLoaders = {
  "cs": [],
  "en": []
}

export const vueI18nConfigs = [
  () => import("../../../layers/config/i18n.config.ts?hash=bfb7ab2f&config=1" /* webpackChunkName: "__i18n_config_ts_bfb7ab2f" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "cs",
      "iso": "cs-CZ",
      "name": "Česky",
      "domain": "www.startupjobs.cz",
      "apiDomain": "https://www.startupjobs.cz"
    },
    {
      "code": "en",
      "iso": "en",
      "name": "English",
      "domain": "www.startupjobs.com",
      "apiDomain": "https://www.startupjobs.com"
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "iso": "cs-CZ",
    "name": "Česky",
    "domain": "www.startupjobs.cz",
    "apiDomain": "https://www.startupjobs.cz",
    "files": []
  },
  {
    "code": "en",
    "iso": "en",
    "name": "English",
    "domain": "www.startupjobs.com",
    "apiDomain": "https://www.startupjobs.com",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
