export default defineAppConfig({
  ui: {
    primary: "blue",
    gray: "neutral",
    tooltip: {
      wrapper: "w-full",
      background: "bg-neutral-900",
      color: "text-white",
      base: "[@media(pointer:coarse)]:hidden p-2 caption-xs truncate relative",
      rounded: "",
    },
    strategy: "override",
    notifications: {
      container: "space-y-3",
      wrapper: "fixed w-full flex flex-col !p-0 justify-end z-[55]",
      width: "w-full max-w-md",
      position: "top-0 right-0",
    },
    notification: {
      wrapper: "px-3 py-2",
      container: "relative font-bold text-base w-full",
      rounded: "rounded-none",
      padding: "px-0 pt-4",
      title: "w-full pr-6 mb-2",
      description: "text-xs leading-4 font-medium mt-0",
      ring: "",
      progress: {
        base: "absolute bottom-0 end-0 start-0 h-1",
        background: "bg-black h-1",
      },
    },
    range: {
      wrapper: "relative w-full flex items-center group",
      rounded: "rounded-none",
      progress: {
        rounded: "rounded-none",
        background: "bg-black",
      },
      track: {
        background:
          "[&::-webkit-slider-runnable-track]:bg-neutral-300 [&::-moz-range-track]:bg-neutral-300",
        rounded: "rounded-none",
      },
      thumb: {
        base: "[&::-webkit-slider-thumb]:relative [&::-moz-range-thumb]:relative [&::-webkit-slider-thumb]:z-[1] [&::-moz-range-thumb]:z-[1] [&::-webkit-slider-thumb]:appearance-none [&::-moz-range-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-none [&::-moz-range-thumb]:rounded-none [&::-moz-range-thumb]:border-0",
        size: {
          sm: "group-hover:[&::-webkit-slider-thumb]:h-3 group-hover:[&::-moz-range-thumb]:h-3 [&::-webkit-slider-thumb]:h-0 [&::-moz-range-thumb]:h-0 [&::-webkit-slider-thumb]:w-3 [&::-moz-range-thumb]:w-3 [&::-webkit-slider-thumb]:-mt-1 [&::-moz-range-thumb]:-mt-1",
        },
      },
    },
  },
});
