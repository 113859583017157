<template>
  <InputStrengthIndicator
    class="!mt-2.5"
    :max-score="5"
    :score="strength"
    :is-error="false"
  />
</template>

<script setup lang="ts">
import InputStrengthIndicator from "@ui/components/input/InputStrengthIndicator.vue";
import { usePasswordStrength } from "../composables/_usePasswordStrength";

const props = defineProps<{
  value?: string;
}>();

const password = toRef(() => props.value);
const strength = usePasswordStrength(password);
</script>
