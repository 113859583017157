<template>
  <div class="relative my-8 h-px w-full bg-neutral-200 px-6">
    <div class="absolute inset-x-0 -top-3 flex justify-center">
      <div
        class="-tracking-1 bg-white px-3 text-center font-semibold leading-snug"
      >
        {{ t("separator") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      separator: "Nebo přes e-mail",
    },
    en: {
      separator: "Or login via e-mail",
    },
  },
});
</script>
