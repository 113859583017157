import { createInjectionState } from "@vueuse/core";

interface AuthContextOptions {
  signUpForceRedirectTo?: string;
  signInForceRedirectTo?: string;
  gdprUrl: string;
  termsUrl: string;
}

const [useProvideAuthContext, useAuthContext] = createInjectionState(
  (context: AuthContextOptions) => {
    return context;
  },
);

function useAuthContextOrThrow() {
  const authContext = useAuthContext();
  if (authContext == null)
    throw new Error("`useAuthContext` must be used within a AuthProvider");
  return authContext;
}

export { useProvideAuthContext, useAuthContextOrThrow };
