<template>
  <form class="space-y-6 p-6" @submit="onSubmit">
    <InputGroup
      name="email"
      label="E-mail"
      type="email"
      :placeholder="t('placeholder')"
    >
      <template #icon>
        <IconEmail />
      </template>
    </InputGroup>

    <BaseDialogDescription class="-tracking-1 mb-0 text-sm font-semibold">
      {{ t("description") }}
    </BaseDialogDescription>

    <BaseButton
      type="submit"
      class="mt-6 w-full"
      :class="{ 'animate-pulse': isPending }"
      :disabled="isPending"
    >
      {{ t("send") }}
    </BaseButton>
  </form>
</template>

<script setup lang="ts">
import { DialogDescription as BaseDialogDescription } from "@ui/components/base/dialog";
import IconEmail from "@ui/components/icon/Email.vue";
import InputGroup from "@ui/components/input/InputGroup.vue";
import { Button as BaseButton } from "@ui/components/base/button";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import * as z from "zod";
import { useRequestPasswordReset } from "../composables/useRequestPasswordReset";
import { toast } from "vue-sonner";
import { SonnerError } from "#components";
import ResetLinkSentToast from "./_ResetLinkSentToast.vue";

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      placeholder: "Váš e-mail",
      send: "Odeslat",
      description:
        "Zadejte svůj e-mail, na který vám zašleme odkaz pro obnovení hesla.",
      validation: {
        required: "Toto pole je povinné",
        email: "Neplatný formát e-mailu",
      },
    },
    en: {
      placeholder: "Your e-mail address",
      send: "Send",
      description:
        "Enter your email address and we will send you a link to reset your password.",
      validation: {
        required: "This field is mandatory",
        email: "Invalid email format",
      },
    },
  },
});

const formSchema = toTypedSchema(
  z.object({
    email: z
      .string({ required_error: t("validation.required") })
      .email({ message: t("validation.email") }),
  }),
);

const { handleSubmit } = useForm({
  validationSchema: formSchema,
});

const { requestPasswordReset, isPending } = useRequestPasswordReset();

const onSubmit = handleSubmit(async (values) => {
  try {
    await requestPasswordReset(values);

    toast.custom(markRaw(ResetLinkSentToast));
  } catch (error) {
    toast.custom(markRaw(SonnerError));
  }
});
</script>
