interface UsePasswordStrength {
  (password: Ref<string | undefined>): ComputedRef<number>;
}

export function usePasswordStrengthImpl(password: Ref<string | undefined>) {
  const strength = computed(() => {
    if (!password.value) {
      return 0;
    }
    let score = 0;

    // Check the length of the password
    if (password.value.length >= 6) score++;
    if (password.value.length >= 10) score++;

    // Check for letters and numbers
    if (/[a-z]/.test(password.value)) score++;
    if (/[A-Z]/.test(password.value)) score++;
    if (/\d/.test(password.value)) score++;

    // Check for special characters
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password.value)) score++;

    // Ensure maximum score is 5
    return Math.min(score, 5);
  });

  return strength;
}

export const usePasswordStrength: UsePasswordStrength = usePasswordStrengthImpl;
