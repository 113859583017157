<script setup lang="ts">
import DialogAuth from "./_DialogAuth.vue";
import DialogTwoFA from "./_DialogTwoFA.vue";
import DialogForgottenPassword from "./_DialogForgottenPassword.vue";
import DialogSignOut from "./_DialogSignOut.vue";
import { useProvideAuthContext } from "@auth/composables/_useAuthContext";

const props = defineProps<{
  signInForceRedirectTo?: string;
  signUpForceRedirectTo?: string;
  gdprUrl: string;
  termsUrl: string;
}>();

useProvideAuthContext({
  gdprUrl: props.gdprUrl,
  termsUrl: props.termsUrl,
  signInForceRedirectTo: props.signInForceRedirectTo,
  signUpForceRedirectTo: props.signUpForceRedirectTo,
});
</script>

<template>
  <slot />

  <DialogAuth />
  <DialogTwoFA />
  <DialogForgottenPassword />
  <DialogSignOut />
</template>
