<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_16334_118040)">
      <path
        d="M13.7109 0.710938L0.710938 13.7109"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M13.7109 13.7109L0.710938 0.710938"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_16334_118040">
        <rect width="14.41" height="14.41" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
