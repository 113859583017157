<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { Label, type LabelProps } from "radix-vue";
import { cn } from "@ui/lib/utils";
import { type LabelVariants, labelVariants } from ".";

export type BaseLabelProps = LabelProps & {
  class?: HTMLAttributes["class"];
  orientation?: LabelVariants["orientation"];
  color?: LabelVariants["color"];
};

const props = withDefaults(defineProps<BaseLabelProps>(), {
  class: "",
  orientation: "vertical",
  color: "primary",
});

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <Label
    v-bind="delegatedProps"
    :class="cn(labelVariants({ orientation, color }), props.class)"
  >
    <slot />
  </Label>
</template>
