import { $fetch } from "ofetch";

export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const apiFetch = $fetch.create({
      baseURL: apiBaseUrl(),
      credentials: import.meta.client ? "include" : undefined,
      headers: { Accept: "application/json", ...useRequestHeaders(["cookie"]) },
    });

    globalThis.$apiFetch = apiFetch;

    return {
      provide: {
        apiFetch,
      },
    };
  },
});
