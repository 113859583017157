<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { useVModel } from "@vueuse/core";
import { cn } from "@ui/lib/utils";
import { useFormField } from "@ui/components/base/form/useFormField";

const props = defineProps<{
  defaultValue?: string | number;
  modelValue?: string | number | string[];
  class?: HTMLAttributes["class"];
}>();

const emits = defineEmits<{
  (e: "update:modelValue", payload: string | number): void;
}>();

const modelValue = useVModel(props, "modelValue", emits, {
  passive: true,
  defaultValue: props.defaultValue,
});

const { error } = useFormField();
</script>

<template>
  <input
    v-model="modelValue"
    :class="
      cn(
        'label-base ring-1.5 flex h-12 w-full p-3 ring-inset ring-neutral-200 ring-offset-white transition-colors placeholder:text-neutral-400 focus-visible:bg-white focus-visible:caret-blue-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-blue-700 disabled:cursor-not-allowed disabled:opacity-50',
        props.class,
        error && 'ring-red-500',
      )
    "
  />
</template>
<style scoped>
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
