<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_39258_358256)">
      <path d="M19 14H1V19H19V14Z" stroke-width="1.5" stroke-miterlimit="10" />
      <path
        d="M10 10C12.4853 10 14.5 7.98528 14.5 5.5C14.5 3.01472 12.4853 1 10 1C7.51472 1 5.5 3.01472 5.5 5.5C5.5 7.98528 7.51472 10 10 10Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_39258_358256">
        <rect
          width="19.5"
          height="19.5"
          fill="white"
          transform="translate(0.25 0.25)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
