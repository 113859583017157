<script lang="ts" setup>
import { useFormField } from "./useFormField";
import { cn } from "@ui/lib/utils";
import { Label } from "../label";
import type { BaseLabelProps } from "../label/Label.vue";

const props = withDefaults(defineProps<BaseLabelProps>(), {
  class: "",
});

const { formItemId } = useFormField();
</script>

<template>
  <Label
    :for="formItemId"
    :orientation="props.orientation"
    :color="props.color"
    :class="cn(props.class)"
  >
    <slot />
  </Label>
</template>
