<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_39258_358721)">
      <path
        d="M11.77 11.7705C11.32 12.2205 10.69 12.5005 10 12.5005C8.62 12.5005 7.5 11.3805 7.5 10.0005C7.5 9.31047 7.78 8.68047 8.23 8.23047"
        fill="currentColor"
      />
      <path
        d="M11.77 11.7705C11.32 12.2205 10.69 12.5005 10 12.5005C8.62 12.5005 7.5 11.3805 7.5 10.0005C7.5 9.31047 7.78 8.68047 8.23 8.23047"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1 1L19 19"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M7.77938 4.6C8.45938 4.39 9.19937 4.25 9.99937 4.25C11.3894 4.25 12.5894 4.62 13.6294 5.18C15.1794 6.02 16.3694 7.3 17.1594 8.39C17.5494 8.93 17.8494 9.42 18.0394 9.77C18.0894 9.86 18.1294 9.94 18.1694 10.01C17.8894 10.55 17.1994 11.74 16.1094 12.9C16.1094 12.9 16.0994 12.91 16.0894 12.92L17.1394 13.97C17.6094 13.47 18.0394 12.98 18.3694 12.51C19.2394 11.31 19.6694 10.35 19.6894 10.32L19.8294 10.01L19.6894 9.7C19.6894 9.7 18.9194 7.97 17.3494 6.25C16.5594 5.39 15.5694 4.52 14.3494 3.86C13.1294 3.2 11.6694 2.75 10.0094 2.75C8.74938 2.75 7.61937 3.01 6.60938 3.42L7.78938 4.6H7.77938Z"
        fill="currentColor"
      />
      <path
        d="M14.3663 14.3708C13.1763 15.1808 11.7262 15.7508 9.99625 15.7508C8.60625 15.7508 7.40625 15.3808 6.36625 14.8208C4.81625 13.9808 3.62625 12.7008 2.83625 11.6108C2.44625 11.0708 2.14625 10.5808 1.95625 10.2308C1.90625 10.1408 1.86625 10.0608 1.82625 9.99078C2.10625 9.45078 2.79625 8.26078 3.88625 7.10078C4.38625 6.57078 4.96625 6.06078 5.61625 5.62078L4.54625 4.55078C3.27625 5.46078 2.30625 6.55078 1.61625 7.49078C0.74625 8.69078 0.31625 9.65078 0.29625 9.68078L0.15625 9.99078L0.29625 10.3008C0.29625 10.3008 1.06625 12.0308 2.63625 13.7508C3.42625 14.6108 4.41625 15.4808 5.63625 16.1408C6.85625 16.8008 8.31625 17.2508 9.97625 17.2508C11.6363 17.2508 13.0963 16.8008 14.3163 16.1408C14.7063 15.9308 15.0662 15.6808 15.4163 15.4408L14.3463 14.3708H14.3663Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_39258_358721">
        <rect
          width="19.64"
          height="19.06"
          fill="white"
          transform="translate(0.179688 0.470703)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
