import { type VariantProps, cva } from "class-variance-authority";

export { default as Toaster } from "./Sonner.vue";
export { default as SonnerStructure } from "./SonnerStructure.vue";
export { default as SonnerError } from "../../sonner/SonnerError.vue";

export const sonnerVariants = cva(
  "flex w-[100vw] flex-col gap-y-2 px-3 pb-2 pt-6 font-sans md:w-[768px]",
  {
    variants: {
      variant: {
        success: "bg-green-400 shadow-[0_2px_7px_0_rgba(19,234,138,0.40)]",
        error: "bg-red-500 text-white shadow-[0_2px_7px_0_rgba(240,22,60,0.40)]",
      },
    },
    defaultVariants: {
      variant: "success",
    },
  },
);

export type SonnerVariants = VariantProps<typeof sonnerVariants>;
