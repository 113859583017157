import { cva, type VariantProps } from "class-variance-authority";

export { default as Label } from "./Label.vue";

export const labelVariants = cva(
  "-tracking-1 font-medium leading-6 peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      color: {
        primary: "text-neutral-500",
        inverse: "text-white",
        black: "text-black",
      },
      orientation: {
        vertical: "text-sm",
        horizontal: "cursor-pointer text-base hover:text-inherit",
      },
    },
    defaultVariants: {
      color: "primary",
      orientation: "vertical",
    },
  },
);

export type LabelVariants = VariantProps<typeof labelVariants>;
